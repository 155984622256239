
.App {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#root {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


/* copied from client app to 'confuse our app' */
html {
/*     font-size: 100%; */
    /* font-size: 10px; */
}

/* label {
    margin-bottom: 45px;
} */